@import _/var

.banner-slick
    overflow: hidden
.banner
    width: 640px
    height: 405px !important
    overflow: hidden
    *zoom: 1
    *z-index: -10
    .banner-img
        background-repeat: no-repeat !important
        background-position: center center
        background-size: cover
        height: 405px !important
        position: relative
    .banner-txt
        position: absolute
        width: 100%
        bottom: 0px
        left: 0px
        z-index: 10
        height: 40px
        color: #fff
        padding-left: 15px
        line-height: 40px
        background-color: rgba(0,0,0,0.5)
        p 
            +tp(368px)
    .slick-dots
        text-align: center
        position: absolute
        bottom: 18px 
        font-size: 0px
        right: 23px
        z-index: 15

        button
            width: 28px
            height: 5px
            border: 0
            cursor: pointer
            line-height: 0
            padding: 0px
            outline: none
            background-color: #a5a5a5

        li
            display: inline-block
            *display: inline
            *zoom: 1
            margin-left: 7px
            width: 28px
            height: 5px
            zoom: 1
    .slick-active 
        button
            background-color: #1a88d4
.Hang
    margin-top: 20px
    overflow: hidden
    *zoom: 1
.box
    overflow: hidden
    *zoom: 1
    &.news
        width: 540px
        height: 405px
    &.notice,&.jiaoxue
        margin-right: 30px
    &.notice,&.jiaoxue,&.dang
        width: 380px
        height: 330px
    &.tuanxue
        width: 790px
        height: 330px
        .box-tuanxue
            width: 380px
        .box-list
            width: 380px
            padding-top: 0px
        .box-con
            padding-top: 20px
    &.bianjie
        height: 330px
.box-ti
    line-height: 32px
    border-bottom: 1px solid #dcdcdc
    a 
        font-size: 12px 
        color: #999
        &:hover
            color: $main
    h3
        line-height: 31px
        border-bottom: 3px solid $main
        color: $main
        padding-left: 25px
        margin-bottom: -2px
        *margin-bottom: 0px
        font-size: 18px
        font-weight: bold
        &.news-ti 
            +bgp(icon1,left center)
        &.notice-ti 
            +bgp(icon2,left center)
        &.jiaoxue-ti 
            +bgp(icon3,left center)
        &.dang-ti 
            +bgp(icon4,left center)
        &.tuanxue-ti 
            +bgp(icon5,left center)
        &.bianjie-ti 
            +bgp(icon5,left center)
        &.school-ti
            +bgp(icon6,left center)
.box-list
    padding-top: 10px
    li
        line-height: 2.4
        &:hover
            color: $main
            .dotted
                background-color: $main
            a
                color: $main

        a
            +tp(70%)
            font-size: 14px 
            color: #666
        .dotted
            width: 4px
            height: 4px
            background-color: #cccccc
            margin-top: 15px
            margin-right: 10px
        .time
            font-size: 14px 
            color: #999


.box-intro
    height: 154px
    display: block
    overflow: hidden
    border-bottom: 1px dashed #c0c0c0
    .box-intro-ti
        margin-top: 24px
        margin-bottom: 10px
        color: #666
        *height: 16px
        font-size: 16px
        font-weight: bold
        line-height: 1
        +tp(100%)
    .box-intro-con
        font-size: 14px
        color: #666
        line-height: 30px
        +mp(3)
        *height: 88.8px
.box-tuanxue
    display: block
    height: 275px
    background-color: #f6f9fd
    dt
        width: 52px
        border-right: 2px solid #fff
        font-size: 16px 
        color: #0e7dca
        line-height: 275px
        padding: 0px 10px
        text-align: center
        span
            font-weight: bold
            line-height: 28px
            display: inline-block
            vertical-align: middle
            *zoom: 1
            *display: inline

    dd
        width: 328px
        line-height: 275px
        font-size: 14px 
        color: #666
        padding: 0px 25px
        span 
            line-height: 30px
            display: inline-block
            vertical-align: middle
            *zoom: 1
            *display: inline
            height: 237px
            overflow: hidden
            ext-overflow: ellipsis


.box-bianjie
    margin-top: 20px
    a 
        display: block
        width: 380px
        height: 90px
        line-height: 90px
        font-size: 24px
        color: #fff
        font-weight: bold
        text-align: center
    .box-bianjie-item1
        +bg(line1)
        margin-bottom: 10px
    .box-bianjie-item2
        +bg(line2)
        margin-bottom: 10px
    .box-bianjie-item3
        +bg(line3)
        height: 80px
        line-height: 80px
.box-school
    .slick-list
        margin-left: -20px
.school-item
    
    margin-left: 20px
    &:hover
        img
            transform: scale(1.1)
        p
            color: $main
    .school-item-img
        overflow: hidden
        width: 100%
        height: 170px
        border: 1px solid #e7e7e7
        +bs(0px 3px 0px 1px #ebebeb)
        img 
            width: 100%
            height: 100%
            transition: all .5s
    p
        font-size: 14px 
        +tp(100%)
        color: #333333
        line-height: 50px
        text-align: center


.link
    height: 121px
    background-color: #f9f9f9
    .linkfl
        width: 120px
        height: 121px
        +bg(link,left center)
    .linkfr
        padding-top: 39px
    .link-item
        position: relative
        margin-left: 20px
        &:hover
            dd 
                display: block
        dt 
            width: 330px
            height: 40px
            font-size: 14px
            color: #999
            line-height: 40px
            text-align: center
            +bgp(arrow,296px center)
            background-color: #f2f1ee
            cursor: pointer

        dd
            position: absolute
            top: 40px
            left: 0px
            width: 100%
            background-color: #f6f6f6
            z-index: 99
            padding: 5px 0px
            display: none
            a 
                display: block
                text-align: center
                line-height: 35px
                &:hover 
                    color: $main