// ------------------------------
// (*￣︿￣) Mixin
// ------------------------------

$fixs: -webket-, -moz-, -ms-, ""
// 属性前缀
=fix($property, $value)
    @each $fix in $fixs
        #{$fix}#{$property}: $value


// 属性值前缀
=fixval($property, $value)
    @each $fix in $fixs
        #{$property}: #{$fix}#{$value}

// 线性渐变
// linear gradient
=lg($value...)
    +fixval(background-image, linear-gradient($value))
//阴影
=bs($value...)
    +fix(box-shadow, $value)
// 圆角
=br($value...)
    +fix(border-radius, $value)

// 背景透明度
=bgo($opacity, $color: #000)
    background-color: rgba($color, $opacity)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{ie-hex-str(rgba($color, $opacity))},endColorstr=#{ie-hex-str(rgba($color, $opacity))})
    :root &
        filter: none

// 背景
=bg($name, $position: 0 0, $color: transparent, $repeat: no-repeat)
    background: $color url('#{$name}.jpg') $position $repeat
=bgp($name, $position: 0 0, $color: transparent, $repeat: no-repeat)
    background: $color url('#{$name}.png') $position $repeat

// 单行文字溢出时出现省略号，需设定宽度
=tp($width: 100%)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: $width

// 多行文字省略号
=mp($row)
    overflow : hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: $row
    -webkit-box-orient: vertical

// 箭头
=arrow($dir, $size, $bg)
    content: ""
    display: inline-block
    vertical-align: middle
    border: $size solid $bg
    @if $dir == left
        border-color: transparent $bg transparent transparent
    @if $dir == right
        border-color: transparent transparent transparent $bg
    @if $dir == bottom
        margin-top: $size / 2
        border-color: $bg transparent transparent transparent
    @if $dir == top
        margin-top: - ($size / 2)
        border-color: transparent transparent $bg transparent
